import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['repos', 'reposLoader']
  
  connect() {
    this.loadRepos();
  }

  loadRepos() {
    this.reposTarget.options.length = 0;
    fetch(this.data.get('accountPath') + '/github_repos.json', {
      method: 'GET',
      credentials: 'same-origin'
    }).then((response) => {
      return response.json();
    }).then((response) => {
      for(var index in response) {
        this.reposTarget.options[this.reposTarget.options.length] = new Option(response[index][0], response[index][1] + ":" + response[index][0]);
        if (this.data.get("repoId") == response[index][1]) {
          this.reposTarget.selectedIndex = this.reposTarget.options.length - 1
        }
      }

      this.reposTarget.style.display = 'block';
      this.reposLoaderTarget.style.display = 'none';
    })
  } 

  selectedrepoId() {
    return this.reposTarget.options[this.reposTarget.selectedIndex].value.split(':')[0];
  }
}
